<template>
	<div class="wscn-http404-container">
		<div class="wscn-http404">
			<div class="pic-404">
				<el-image class="pic-404__parent" :src="require('assets/404_images/404.png')" alt="404" />
				<el-image class="pic-404__child left" :src="require('assets/404_images/404_cloud.png')" alt="404" />
				<el-image class="pic-404__child mid" :src="require('assets/404_images/404_cloud.png')" alt="404" />
				<el-image class="pic-404__child right" :src="require('assets/404_images/404_cloud.png')" alt="404" />
			</div>
			<div class="bullshit">
				<div class="bullshit__oops">页面走丢了!</div>
				<div class="bullshit__headline">页面没找到或没有进入权限</div>
				<div class="bullshit__info">单击下面的按钮返回首页。</div>
				<div class="bullshit__return-home" @click="toIndex()">回首页</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Page404',
		methods: {
			toIndex() {
				let routes = JSON.parse(sessionStorage.getItem('routes'))||[]
				let toRouter = this.getPath(routes)
				if (toRouter) {
					this.$router.replace(toRouter).catch(err => {})
				} else {
					eltips('菜单设置有误，请联系管理员重新设置', 'error')
				}
			},
			getPath(list) {
				let path, props, toRouter
				for (let i = 0; i < list.length; i++) {
					let item = list[i]
					if (item.path) {
						path = item.path
						props = item.props || null
						toRouter = {
							'path': path
						}
						if (props) toRouter.query = {
							'props': props
						}
						return toRouter
					}
					if (item._child.length > 0) {
						return this.getPath(item._child)
					}
				}
				return false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.wscn-http404-container {
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		
		.wscn-http404 {
			position: relative;
			// width: 1200px;
			margin: 0 auto;
			min-height: 100%;
			overflow: hidden;
		
			.pic-404 {
				position: relative;
				float: left;
				width: 600px;
				overflow: hidden;
		
				&__parent {
					width: 100%;
				}
		
				&__child {
					position: absolute;
		
					&.left {
						width: 80px;
						top: 17px;
						left: 220px;
						opacity: 0;
						animation-name: cloudLeft;
						animation-duration: 2s;
						animation-timing-function: linear;
						animation-fill-mode: forwards;
						animation-delay: 1s;
						animation-iteration-count:infinite;
					}
		
					&.mid {
						width: 46px;
						top: 10px;
						left: 420px;
						opacity: 0;
						animation-name: cloudMid;
						animation-duration: 2s;
						animation-timing-function: linear;
						animation-fill-mode: forwards;
						animation-delay: 1.2s;
						animation-iteration-count:infinite;
					}
		
					&.right {
						width: 62px;
						top: 100px;
						left: 500px;
						opacity: 0;
						animation-name: cloudRight;
						animation-duration: 2s;
						animation-timing-function: linear;
						animation-fill-mode: forwards;
						animation-delay: 1s;
						animation-iteration-count:infinite;
					}
		
					@keyframes cloudLeft {
						0% {
							top: 17px;
							left: 220px;
							opacity: 0;
						}
		
						20% {
							top: 33px;
							left: 188px;
							opacity: 1;
						}
		
						80% {
							top: 81px;
							left: 92px;
							opacity: 1;
						}
		
						100% {
							top: 97px;
							left: 60px;
							opacity: 0;
						}
					}
		
					@keyframes cloudMid {
						0% {
							top: 10px;
							left: 420px;
							opacity: 0;
						}
		
						20% {
							top: 40px;
							left: 360px;
							opacity: 1;
						}
		
						70% {
							top: 130px;
							left: 180px;
							opacity: 1;
						}
		
						100% {
							top: 160px;
							left: 120px;
							opacity: 0;
						}
					}
		
					@keyframes cloudRight {
						0% {
							top: 100px;
							left: 500px;
							opacity: 0;
						}
		
						20% {
							top: 120px;
							left: 460px;
							opacity: 1;
						}
		
						80% {
							top: 180px;
							left: 340px;
							opacity: 1;
						}
		
						100% {
							top: 200px;
							left: 300px;
							opacity: 0;
						}
					}
				}
			}
		
			.bullshit {
				position: relative;
				float: left;
				width: 300px;
				padding: 30px 0;
				overflow: hidden;
		
				&__oops {
					font-size: 32px;
					font-weight: bold;
					line-height: 40px;
					color: #1482f0;
					opacity: 0;
					margin-bottom: 20px;
					animation-name: slideUp;
					animation-duration: 0.5s;
					animation-fill-mode: forwards;
				}
		
				&__headline {
					font-size: 20px;
					line-height: 24px;
					color: #222;
					font-weight: bold;
					opacity: 0;
					margin-bottom: 10px;
					animation-name: slideUp;
					animation-duration: 0.5s;
					animation-delay: 0.1s;
					animation-fill-mode: forwards;
				}
		
				&__info {
					font-size: 13px;
					line-height: 21px;
					color: grey;
					opacity: 0;
					margin-bottom: 30px;
					animation-name: slideUp;
					animation-duration: 0.5s;
					animation-delay: 0.2s;
					animation-fill-mode: forwards;
				}
		
				&__return-home {
					display: block;
					float: left;
					width: 110px;
					height: 36px;
					background: #1482f0;
					border-radius: 100px;
					text-align: center;
					color: #ffffff;
					opacity: 0;
					font-size: 14px;
					line-height: 36px;
					cursor: pointer;
					animation-name: slideUp;
					animation-duration: 0.5s;
					animation-delay: 0.3s;
					animation-fill-mode: forwards;
				}
		
				@keyframes slideUp {
					0% {
						transform: translateY(60px);
						opacity: 0;
					}
		
					100% {
						transform: translateY(0);
						opacity: 1;
					}
				}
			}
		}
	}

</style>